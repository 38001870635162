import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = () => {
    const credentials = { email, password };

    // Make an API request to log in
    fetch('https://flyuyuzbewdsgoff.flyoff.xyz/api/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.message === "Login failed"){
             alert ("Login failed");
             return;
        }
        localStorage.setItem('userId' , data.userId);
        localStorage.setItem('username' , data.name);
        localStorage.setItem('loggedIn', true);
        setIsAuthenticated(true);
        history.push("/");
      })
      .catch((error) => {
        alert('Error during login:', error);
      });
  };

  return (
    <div className="container">
      <h1>Login</h1>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          className="form-control"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input
          type="password"
          name="password"
          className="form-control"
          onChange={handleInputChange}
          required
        />
      </div>
      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={handleLogin}
      >
        Login
      </button>
      <a
        type="button"
        className=" mt-2 mx-2"
        onClick={() => history.push('/register')}
      >
       Don't have account? Register
      </a>
    </div>
  );
};

export default Login;

/* Updated Card.js */
import React from "react";
import "./Card.css";

function Card(props) {
  const { id, title } = props.card;

  const completeTask = () => {
    // Send a PUT request to update a card
    fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/tasks/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => props.removeCard(props.boardId, id))
      .catch((error) => console.error("Error updating card: " + error));
  }

  return (
    <div className="card" draggable onDragEnd={() => props.dragEnded(props.boardId, id)} onDragEnter={() => props.dragEntered(props.boardId, id)}>
      <div className="card_content">
        <div className="checkbox" onClick={completeTask}>
          <div className="checkmark"></div>
        </div>
        <div className="card_title">{title}</div>
      </div>
    </div>
  );
}

export default Card;

import React, { useState } from "react";
import { MoreHorizontal } from "react-feather";
import Card from "../Card/Card";
import Dropdown from "../Dropdown/Dropdown";
import Editable from "../Editabled/Editable";

import "./Board.css";

function Board(props) {
  const [showDropdown, setShowDropdown] = useState(false);

  function deleteBoard(props) {
    // Send a DELETE request to delete the board
    fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/lists/${props.board.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: 1,
      }),
    })
      .then((response) => response.json())
      .then((data) => props.removeBoard(props.board.id))
      .catch((error) => console.error("Error deleting board: " + error));
  }

  return (
    <div className="board">
      <div className="board_header">
        <p className="board_header_title">
          {props.board?.title}
          <span>{props.board?.tasks?.length || 0}</span>
        </p>
        <div
          className="board_header_title_more"
          onClick={() => setShowDropdown(true)}
        >
          <MoreHorizontal />
          {showDropdown && (
            <Dropdown
              class="board_dropdown"
              onClose={() => setShowDropdown(false)}
            >
              <p onClick={() => deleteBoard(props)}>Delete Board</p>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="board_cards custom-scroll">
        {props.board?.tasks?.map((item) => (
          <Card
            key={item.id}
            card={item}
            boardId={props.board.id}
            removeCard={props.removeCard}
            dragEntered={props.dragEntered}
            dragEnded={props.dragEnded}
            updateCard={props.updateCard}
          />
        ))}

        {/* Empty drop area for tasks */}
        <div
          className="empty-drop-area"
          onDragOver={(e) => {
            e.preventDefault();
            props.dragEntered(props.board.id, null); // Pass null as the card ID
          }}
          onDrop={() => props.dragEnded(props.board.id, null)}
        >
          {props.board?.tasks?.length === 0 && (
            <p className="empty-drop-area_text">Drop cards here</p>
          )}

        </div>

        <Editable
          text="+ Add Card"
          placeholder="Enter Card Title"
          displayClass="board_add-card"
          editClass="board_add-card_edit"
          onSubmit={(value) => props.addCard(props.board?.id, value)}
        />
      </div>
    </div>
  );
}

export default Board;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'name') {
            setName(value);
        }
    };

    const handleRegister = () => {
        // Make an API request to register
        fetch('https://flyuyuzbewdsgoff.flyoff.xyz/api/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, password }),
        })
            .then((response) => response.json())
            .then((data) => {
                history.push("/login");
            })
            .catch((error) => {
                alert('Error during registration:', error);
            });
    };

    return (
        <div className="container">
            <h1>Register</h1>
            <div className="form-group">
                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <label>Email:</label>
                <input
                    type="email"
                    name="email"
                    className="form-control"
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <label>Password:</label>
                <input
                    type="password"
                    name="password"
                    className="form-control"
                    onChange={handleInputChange}
                    required
                />
            </div>
            <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleRegister}
            >
                Register
            </button>
            <a
        type="button"
        className=" mt-2 mx-2"
        onClick={() => history.push('/login')}
      >
       Already have account? Login
      </a>
        </div>
    );
};

export default Register;

import React, { useEffect, useState } from "react";
import Board from "../Board/Board";
import "./BoardList.css";
import Editable from "../Editabled/Editable"

export default function BoardList() {
    const [boards, setBoards] = useState([]);
    const [targetCard, setTargetCard] = useState({ bid: "", cid: "" });


    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        fetch("https://flyuyuzbewdsgoff.flyoff.xyz/api/lists/getAll", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: localStorage.getItem("userId") }),
        })
            .then((response) => response.json())
            .then((data) => setBoards(data))
            .catch((error) => console.error("Error fetching boards: " + error));
    }

    const addBoard = (name) => {
        // Send a POST request to create a new board
        fetch("https://flyuyuzbewdsgoff.flyoff.xyz/api/lists/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: localStorage.getItem("userId"),
                title: name,
            }),
        })
            .then((response) => response.json())
            .then((newBoard) => {setBoards([...boards, newBoard]);
                getAll();
            })
            .catch((error) => console.error("Error adding board: " + error));
        
    };



    const removeBoard = (id) => {
        // Send a DELETE request to remove a board
        fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/lists/${id}`, {
            method: "DELETE",
        })
            .then(() => setBoards(boards.filter((board) => board.id !== id)))
            .catch((error) => console.error("Error removing board: " + error));
    };

    const addCardHandler = (id, title) => {
        // Send a POST request to add a card to a board
        fetch("https://flyuyuzbewdsgoff.flyoff.xyz/api/tasks/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                listId: id,
                title,
            }),
        })
            .then((response) => response.json())
            .then((newCard) => {
                setBoards((prevBoards) =>
                    prevBoards.map((board) => {
                        if (board.id === id) {
                            return { ...board, tasks: [...board.tasks, newCard] };
                        }
                        return board;
                    })
                );
            })
            .catch((error) => console.error("Error adding card: " + error));
    };

    const removeCard = (bid, cid) => {
        // Send a DELETE request to remove a card
        fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/tasks/${cid}`, {
            method: "DELETE",
        })
            .then(() => {
                setBoards((prevBoards) =>
                    prevBoards.map((board) => {
                        if (board.id === bid) {
                            return {
                                ...board,
                                tasks: board.tasks.filter((card) => card.id !== cid),
                            };
                        }
                        return board;
                    })
                );
            })
            .catch((error) => console.error("Error removing card: " + error));
    };

    const dragEnded = (bid, cid) => {
        if (targetCard.bid === bid) {
            return; // Do nothing if targetCard.bid is empty
        }
        // Send a PUT request to update the card's list
        fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/tasks/${cid}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ listId: targetCard.bid }),
        })
            .then(() => {
                setBoards((prevBoards) => {
                    const sourceBoard = prevBoards.find((board) => board.id === bid);
                    const sourceCard = sourceBoard.tasks.find((card) => card.id === cid);

                    const updatedBoards = prevBoards.map((board) => {
                        if (board.id === bid) {
                            return {
                                ...board,
                                tasks: board.tasks.filter((card) => card.id !== cid),
                            };
                        }
                        if (board.id === targetCard.bid) {
                            return {
                                ...board,
                                tasks: [...board.tasks, sourceCard],
                            };
                        }
                        return board;
                    });

                    return updatedBoards;
                });
            })
            .catch((error) => console.error("Error updating card: " + error));

        setTargetCard({ bid: "", cid: "" });
    };


    const dragEntered = (bid, cid) => {
        setTargetCard({ bid, cid });
    };

    const updateCard = (bid, cid, card) => {
        // Send a PUT request to update the card
        fetch(`https://flyuyuzbewdsgoff.flyoff.xyz/api/tasks/${cid}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ listId: bid }),
        })
            .then(() => {
                setBoards((prevBoards) => {
                    console.log(cid, bid);
                    return prevBoards.map((board) => {
                        if (board.id === bid) {
                            const updatedTasks = board.tasks.map((c) =>
                                c.id === cid ? { ...c, ...card } : c
                            );
                            return { ...board, tasks: updatedTasks };
                        }
                        return board;
                    });
                });
            })
            .catch((error) => console.error("Error updating card: " + error));
    };

    return (
        <div className="app_boards_container">
            <div className="app_boards">
                {boards.map((item) => (
                    <Board
                        key={item.id}
                        board={item}
                        addCard={addCardHandler}
                        removeBoard={() => removeBoard(item.id)}
                        removeCard={removeCard}
                        dragEnded={dragEnded}
                        dragEntered={dragEntered}
                        updateCard={updateCard}
                    >
                    </Board>
                ))}
                <div className="app_boards_last">
                    <Editable
                        displayClass="app_boards_add-board"
                        editClass="app_boards_add-board_edit"
                        placeholder="Enter Board Name"
                        text="Add Board"
                        buttonText="Add Board"
                        onSubmit={addBoard}
                    />
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
import BoardList from "./Components/BoardList/BoardList";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

export default function App() {
  // Check if the user is authenticated based on the 'loggedIn' key in localStorage
  const loggedIn = localStorage.getItem("loggedIn") === "true";
  const [isAuthenticated, setIsAuthenticated] = useState(loggedIn);
  const username = localStorage.getItem("username");

  // If loggedIn is true, set isAuthenticated to true immediately
  useEffect(() => {
    if (loggedIn) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    // Implement the logout functionality, clear user data from localStorage
    localStorage.clear();
    setIsAuthenticated(false);
  };

  return (
    <div className="app">
      <div className="app_nav d-flex justify-content-between align-items-center">
        <h1>TodoApp</h1>
        {isAuthenticated && (
          <div>
            <h2>Hello {username}</h2> 
            </div>
            )}
        {isAuthenticated && (
            <div>
              <button className="btn btn-danger" onClick={handleLogout}>
                Logout
              </button>
            </div>
        )}
      </div>
      <Router>
        <Switch>
          <Route path="/login">
            <Login setIsAuthenticated={setIsAuthenticated}/>
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/">
            {isAuthenticated ? <BoardList /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </Router>
    </div >
  );
}
